<template>
  <div>
    <CRow>
      <CCol sm="12" class="d-flex justify-content-end py-2">
        <CButton
          color="add"
          class="d-flex align-items-center"
          @click="toggleShowModalCreateTpVehiculo"
          v-c-tooltip="{
                  content: $t('label.tpVehicle'),
                  placement: 'top-end'
                }"
              >
              <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
        </CButton>
      </CCol>
    </CRow>
    <dataTableExtended
     class="align-center-row-datatable"
      size="lg"
      :fields="fields"
      :items="computedDriverList"
      :table-filter="tableText.tableFilterText"
      column-filter
      :items-per-page-select="tableText.itemsPerPageText"
      :items-per-page="tableText.itemsPerPage"
      :noItemsView="tableText.noItemsViewText"
      pagination
      :loading="loadingTable"
      hover
      sorter  
       
    >
      <template #loading>
        <loading/>
      </template>

      <template #Status="{ item }">
        <td class="center-cell">
          <CBadge :color="getBadge(item.Status)">
            {{ $t('label.'+item.Status) }}
          </CBadge>
        </td>
      </template>
      <template #options="{ item }">
        <td class="center-cell">
          <CButtonGroup>
            <CButton
              color="edit"
              size="sm"
              class="text-center"
              v-c-tooltip="{
                content: $t('label.edit')+' '+$t('label.tpVehicle'),
                placement: 'top-end'
              }"
              @click="toggleShowModalEditDriver(item)"
            >
              <CIcon name="pencil" />
            </CButton>
          </CButtonGroup>
        </td>
      </template>
    </dataTableExtended>

    <modalTpVehiculo
      :modal.sync="showModal"
      @updated-modal="updateCLoseModal"
      :title="titleModal"
      :editModal="editModal"
      :tpVehicleSelected="tpVehicleSelected"
      @refresh-data-table="mountedTpDriver"
    />
  </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import modalTpVehiculo from './modal-tp-vehiculo';

function data() {
  return {
    itemsTpVehiculo: [],
    loadingTable: false,
    loadingOverlay: false,
    recordPages: 5,
    showModal: false,
    editModal: false,
    titleModal: '',
    tpVehicleSelected: {},
  };
}

  function fields() {
    return [    
      { label: '#', key: 'Nro', filter: false,_style: 'width:1%; text-align:center;', _classes:'text-center center-cell'},
      { label: this.$t('label.tpVehicle'), key: 'TpVehicleName', _classes:'text-uppercase text-center center-cell' },
      { label: this.$t('label.user'), key: 'TransaLogin', _style:'width:15%;', _classes:'text-uppercase text-center center-cell' },
      { label: this.$t('label.date'), key: 'FormatedDate', _style:'width:15%; ¡', _classes:'text-center center-cell' },
      { label: this.$t('label.status'), key: 'Status', _style:'width:15%; ', _classes:'text-center center-cell'},
      { label: '', key: 'options', sorter: false, filter: false, _style: 'width:1%; ',_classes:'text-center center-cell'   },
    ]
  }

//### ACtualiza el estado una vez cerrado el modal
function updateCLoseModal(event) {
  this.showModal = false;
  this.titleModal = '';
  this.editModal = false;
  this.tpVehicleSelected = {};

}

//## Activa el modal para crear nuevo Estado
function toggleShowModalCreateTpVehiculo() {
  this.showModal = true;
  this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.vehicleType');

}

function toggleShowModalEditDriver(item) {
  this.showModal = true;
  this.tpVehicleSelected = item;
  this.titleModal = this.$t('label.edit')+' '+this.$t('label.vehicleType')+': '+this.tpVehicleSelected.TpVehicleName;
  this.editModal = true;
}

async function mountedTpDriver() {
  //this.loadingOverlay = true;
  this.loadingTable = true;

  await this.$http
    .get('TpVehicle-list')
    .then((response) => {
      if (response.data.data.data !== null) {
        this.itemsTpVehiculo = response.data.data;
      }

      this.totalPages = response.data.data.TotalRecords;
    })
    .finally(() => {
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

//### Funcion computed para formatear los datos de la tabla ###
function computedDriverList() {
  if (this.itemsTpVehiculo.length > 0) {
    return this.itemsTpVehiculo.map((item) => {
      return {
        ...item,
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      };
    });
  }
}

export default {
  name: 'tpVehiculo',
  data,
  mixins: [GeneralMixin, ModalMixin],
  components: {
    modalTpVehiculo,
  },
  methods: {
    toggleShowModalCreateTpVehiculo,
    updateCLoseModal,
    toggleShowModalEditDriver,
    mountedTpDriver,
    //updatePage
  },
  computed: {
    computedDriverList,
    fields,
  },
  mounted: mountedTpDriver,
};
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>
